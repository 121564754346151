import React from 'react'

export const MultiLogoTextImageBackground = () => {
  return (
    <svg
      width="1920"
      height="1277"
      viewBox="0 0 1920 1277"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1920 0H0V547V730V957V1010V1157.61C199.604 1237.92 395.206 1275.02 591.308 1274.55C822.399 1284.48 1056.29 1263.85 1313.18 1219.88C1390.17 1206.7 1629.07 1148.75 1788.81 1110C1846.84 1095.92 1894.42 1084.38 1920 1078.44L1920 1010L1920 957L1920 730V547V0Z"
        fill="#CAE1D7"
      />
    </svg>
  )
}
