import React from 'react'

import {
  CloudinaryImage,
  Layout,
  TitleLarge,
  TitleSmall,
} from 'ethos-design-system'
import PropTypes from 'prop-types'

import styles from './MultiLogoTextImage.module.scss'
import { MultiLogoTextImageBackground } from './MultiLogoTextImageBackground'

export const MultiLogoTextImage = ({ moduleData }: any) => {
  const { heading, subheading, mainImg, logosImg } = moduleData

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.contentWrapper}>
        <Layout.HorizontallyPaddedContainer className={styles.layoutContainer}>
          <div className={styles.container}>
            <div className={styles.imgContainer}>
              <div className={styles.mainImgWrapper}>
                <CloudinaryImage
                  width={[350, 398, 379, 592]}
                  height={[306, 350, 330, 506]}
                  publicId={mainImg.groupImage}
                  alt={mainImg.imageAlt}
                  crop="fit"
                />
              </div>
              <div className={styles.logoImgWrapper}>
                <CloudinaryImage
                  crop="fit"
                  width={[401, 793, 526, 800]}
                  height={[58, 115, 77, 135]}
                  publicId={logosImg.groupImage}
                  alt={logosImg.imageAlt}
                />
              </div>
            </div>
            <div className={styles.contentContainer}>
              <div>
                <div className={styles.headingWrapper}>
                  <TitleLarge.Sans.Medium500>
                    {heading}
                  </TitleLarge.Sans.Medium500>
                </div>
                <div className={styles.subheadingWrapper}>
                  <TitleSmall.Sans.Regular400>
                    {subheading}
                  </TitleSmall.Sans.Regular400>
                </div>
              </div>
            </div>
          </div>
        </Layout.HorizontallyPaddedContainer>
      </div>
      <div className={styles.background}>
        <MultiLogoTextImageBackground />
      </div>
    </div>
  )
}

MultiLogoTextImage.propTypes = {
  moduleData: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    mainImg: PropTypes.shape({
      groupImage: PropTypes.string.isRequired,
      imageAlt: PropTypes.string.isRequired,
    }).isRequired,
    logosImg: PropTypes.shape({
      groupImage: PropTypes.string.isRequired,
      imageAlt: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default MultiLogoTextImage
